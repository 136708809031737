import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

function Loading() {
    return (
        <div>
            <div className={'hero-content'}>
                <h1><FontAwesomeIcon icon={faSpinner} className={'fa-spin'}/></h1>
            </div>
        </div>
    );
}

export default Loading;
