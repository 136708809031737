import "./AboutSite.css"
import i18next from "i18next";

function AboutSite() {
    return (
        <div id="about-site">
            <div id="about-site-card">
                <h1>Project Nietzsche</h1>
                <p><strong>{i18next.t('about_site.version')}</strong>: 0.2</p>
                <p><strong>{i18next.t('about_site.development')}</strong>: {i18next.t('about_site.kadavr95')}</p>
                <p><strong>{i18next.t('about_site.design')}</strong>: {i18next.t('about_site.kadavr95')}</p>
                <p><strong>{i18next.t('about_site.qa')}</strong>: {i18next.t('about_site.kadavr95')}</p>
                <p><strong>{i18next.t('about_site.thanks')}</strong>: {i18next.t('about_site.yuurrraaaa') + ", "}
                    {i18next.t('about_site.andex')}</p>
                <p>© {new Date().getFullYear()} Dimini Inc.</p>
            </div>
        </div>
    );
}

export default AboutSite;
