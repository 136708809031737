import React from 'react'
import {Form, Accordion} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import FormCompletenessBadge from "./FormCompletenessBadge";

const AppearanceDataSection = (props) => {
    let formData = props.formData
    let checkedValues = [formData.appearance_overall, formData.appearance_face, formData.appearance_body,
        formData.appearance_clothes]
    if (formData.max_level === "relationship") {
        checkedValues.push(formData.appearance_dick)
    }
    return (
        <Accordion.Item eventKey="1">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.appearance')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[formData.appearance_overall]}
                                               requiredQuestions={true}/>{' '}
                        <FormCompletenessBadge checkedValues={checkedValues}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="appearance_overall">
                    <Form.Label>
                        {i18next.t('relationships_feedback.appearance_overall')} <RequiredQuestionMark/>
                    </Form.Label>
                    <div key={'appearance_overall'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="appearance_overall"
                                type='radio'
                                readOnly
                                key={'appearance_overall.' + i}
                                id={'appearance_overall.' + i}
                                checked={i === parseInt(props.formData.appearance_overall)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="appearance_face">
                    <Form.Label>{i18next.t('relationships_feedback.appearance_face')}</Form.Label>
                    <div key={'appearance_face'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="appearance_face"
                                type='radio'
                                readOnly
                                key={'appearance_face.' + i}
                                id={'appearance_face.' + i}
                                checked={i === parseInt(props.formData.appearance_face)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="appearance_body">
                    <Form.Label>{i18next.t('relationships_feedback.appearance_body')}</Form.Label>
                    <div key={'appearance_body'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="appearance_body"
                                type='radio'
                                readOnly
                                key={'appearance_body.' + i}
                                id={'appearance_body.' + i}
                                checked={i === parseInt(props.formData.appearance_body)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="appearance_clothes">
                    <Form.Label>{i18next.t('relationships_feedback.appearance_clothes')}</Form.Label>
                    <div key={'appearance_clothes'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="appearance_clothes"
                                type='radio'
                                readOnly
                                key={'appearance_clothes.' + i}
                                id={'appearance_clothes.' + i}
                                checked={i === parseInt(props.formData.appearance_clothes)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                {formData.max_level === "relationship" ?
                    <Form.Group className="mb-3" controlId="appearance_dick">
                        <Form.Label>{i18next.t('relationships_feedback.appearance_dick')}</Form.Label>
                        <div key={'appearance_dick'} className="mb-3">
                            {[1, 2, 3, 4, 5].map(i => (
                                <Form.Check
                                    inline
                                    label={i}
                                    name="appearance_dick"
                                    type='radio'
                                    readOnly
                                    key={'appearance_dick.' + i}
                                    id={'appearance_dick.' + i}
                                    checked={i === parseInt(props.formData.appearance_dick)}
                                    onClick={props.onChange.bind(this)}
                                />
                            ))}
                        </div>
                    </Form.Group> : null}

            </Accordion.Body>
        </Accordion.Item>
    );
}

export default AppearanceDataSection;