import React from "react";

export const themes = {
    dark: {
        foreground: '#123456',
        background: '#789ABC',
    },
    light: {
        foreground: '#FEDCBA',
        background: '#987654',
    },
};

export const SettingsContext = React.createContext({
    lang: localStorage.getItem('lang') || 'en',
    theme: themes.light
});

document.documentElement.lang = localStorage.getItem('lang') || 'en';