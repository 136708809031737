import React from 'react'
import {Form, Accordion, Alert, Button, ButtonGroup} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import FormCompletenessBadge from "./FormCompletenessBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVk, faFacebook, faTwitter, faOdnoklassnikiSquare} from '@fortawesome/free-brands-svg-icons'

const SummaryDataSection = (props) => {
    let formData = props.formData

    return (
        <Accordion.Item eventKey="5">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.summary')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[formData.overall]}
                                               requiredQuestions={true}/>{' '}
                        <FormCompletenessBadge checkedValues={[formData.overall, formData.fit_communication,
                            formData.fit_friendship, formData.fit_relationship, formData.recommend, formData.comment]}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="overall">
                    <Form.Label>
                        {i18next.t('relationships_feedback.overall')} <RequiredQuestionMark/>
                    </Form.Label>
                    <div key={'overall'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="overall"
                                type='radio'
                                readOnly
                                key={'overall.' + i}
                                id={'overall.' + i}
                                checked={i === parseInt(props.formData.overall)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="fit_communication">
                    <Form.Label>
                        {i18next.t('relationships_feedback.fit_communication')}
                    </Form.Label>
                    <div key={'fit_communication'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="fit_communication"
                                type='radio'
                                readOnly
                                key={'fit_communication.' + i}
                                id={'fit_communication.' + i}
                                checked={i === parseInt(props.formData.fit_communication)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="fit_friendship">
                    <Form.Label>
                        {i18next.t('relationships_feedback.fit_friendship')}
                    </Form.Label>
                    <div key={'fit_friendship'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="fit_friendship"
                                type='radio'
                                readOnly
                                key={'fit_friendship.' + i}
                                id={'fit_friendship.' + i}
                                checked={i === parseInt(props.formData.fit_friendship)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="fit_relationship">
                    <Form.Label>
                        {i18next.t('relationships_feedback.fit_relationship')}
                    </Form.Label>
                    <div key={'fit_relationship'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="fit_relationship"
                                type='radio'
                                readOnly
                                key={'fit_relationship.' + i}
                                id={'fit_relationship.' + i}
                                checked={i === parseInt(props.formData.fit_relationship)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="recommend">
                    <Form.Label>
                        {i18next.t('relationships_feedback.recommend')}
                    </Form.Label>
                    <div key={'recommend'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="recommend"
                                type='radio'
                                readOnly
                                key={'recommend.' + i}
                                id={'recommend.' + i}
                                checked={i === parseInt(props.formData.recommend)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                {props.formData.recommend && (props.formData.recommend.toString() === '5') ?
                    <Alert key='primary' variant='primary'>
                        <Alert.Heading>{i18next.t('relationships_feedback.recommend_action')}</Alert.Heading>
                        <div className="d-flex justify-content-start">
                            <ButtonGroup aria-label="Share" className={'socialShare'}>
                                <Button onClick={() => window.open("http://vk.com/share.php?url=" +
                                    "https://vk.com/kadavr95",
                                    "Popup",
                                    "location=1, status=1, scrollbars=1, resizable=1, " +
                                    "directories=1, toolbar=1, titlebar=1, width=600, height=300")}
                                        variant="outline-primary">
                                    <FontAwesomeIcon icon={faVk}/>
                                </Button>
                                <Button onClick={() =>
                                    window.open("http://www.facebook.com/share.php?u=" +
                                        "https://www.facebook.com/kadavr95",
                                        "Popup",
                                        "location=1, status=1, scrollbars=1, resizable=1, " +
                                        "directories=1, toolbar=1, titlebar=1, width=600, height=300")}
                                        variant="outline-primary">
                                    <FontAwesomeIcon icon={faFacebook}/>
                                </Button>
                                <Button onClick={() => window.open("https://twitter.com/intent/tweet?url=" +
                                    "https://twitter.com/kadavr95",
                                    "Popup",
                                    "location=1, status=1, scrollbars=1, resizable=1, " +
                                    "directories=1, toolbar=1, titlebar=1, width=600, height=300")}
                                        variant="outline-primary">
                                    <FontAwesomeIcon icon={faTwitter}/>
                                </Button>
                                <Button onClick={() => window.open(
                                    "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=" +
                                    "https://ok.ru/kadavr95",
                                    "Popup",
                                    "location=1, status=1, scrollbars=1, resizable=1, " +
                                    "directories=1, toolbar=1, titlebar=1, width=600, height=300")}
                                        variant="outline-primary">
                                    <FontAwesomeIcon icon={faOdnoklassnikiSquare}/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Alert> : null}

                <Form.Group className="mb-3" controlId="comment">
                    <Form.Label>{i18next.t('relationships_feedback.comment')}</Form.Label>
                    <Form.Control as="textarea" rows={3}
                                  placeholder={i18next.t('relationships_feedback.comment')}
                                  value={formData.comment}
                                  onChange={props.onChange.bind(this)}/>
                </Form.Group>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default SummaryDataSection;
