import React from 'react'
import {Form, Accordion} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import FormCompletenessBadge from "./FormCompletenessBadge";

const UserDataSection = (props) => {
    let formData = props.formData
    let fail_options = ['appearance_face', 'appearance_body', 'appearance_clothes', 'appearance_dick',
        'personality_character', 'personality_adequacy', 'personality_confidence', 'personality_humor',
        'personality_intelligence', 'personality_views', 'personality_tastes', 'personality_sexual',
        'behavior_general', 'behavior_communication', 'behavior_actions', 'behavior_speech',
        'behavior_movement', 'behavior_attitude',
        'other_money', 'other_smell', 'other_age', 'other_distance',
        'better_option', 'initiated_myself', 'dont_know', 'not_over']
    if (formData.max_level !== "relationship") {
        fail_options = fail_options.filter(item => !['appearance_dick', 'personality_sexual'].includes(item))
    }

    return (
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.title')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[(formData.relationship_fail_reasons_attributes &&
                            formData.relationship_fail_reasons_attributes.filter(el =>
                                !el.marked_as_deleted).length) || formData.other_fail_reason]}
                                               requiredQuestions={true}/>{' '}
                        <FormCompletenessBadge checkedValues={[formData.name, formData.age, formData.city,
                            formData.max_level, (formData.relationship_fail_reasons_attributes &&
                                formData.relationship_fail_reasons_attributes.filter(el =>
                                    !el.marked_as_deleted).length) || formData.other_fail_reason]}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>{i18next.t('relationships_feedback.name')}</Form.Label>
                    <Form.Control type="text"
                                  value={formData.name}
                                  placeholder={i18next.t('relationships_feedback.name')}
                                  onChange={props.onChange.bind(this)}/>
                    <Form.Text className="text-muted">
                        {i18next.t('relationships_feedback.name_comment')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="age">
                    <Form.Label>{i18next.t('relationships_feedback.age')}</Form.Label>
                    <Form.Control type="number" min="10" max="100"
                                  placeholder={i18next.t('relationships_feedback.age')}
                                  value={formData.age}
                                  onChange={props.onChange.bind(this)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="city">
                    <Form.Label>{i18next.t('relationships_feedback.city')}</Form.Label>
                    <Form.Control type="text"
                                  placeholder={i18next.t('relationships_feedback.city')}
                                  value={formData.city}
                                  onChange={props.onChange.bind(this)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="max_level">
                    <Form.Label>{i18next.t('relationships_feedback.max_level')}</Form.Label>
                    <Form.Select aria-label={i18next.t('relationships_feedback.max_level')}
                                 value={formData.max_level}
                                 onChange={props.onChange.bind(this)}
                                 className={props.formData && props.formData.max_level &&
                                 props.formData.max_level !== i18next.t('relationships_feedback.max_level') ?
                                     '' : 'unselected'}>
                        <option className={'unselected'}>
                            {i18next.t('relationships_feedback.max_level')}
                        </option>
                        <option value="void">
                            {i18next.t('relationships_feedback.levels.void')}
                        </option>
                        <option value="text">
                            {i18next.t('relationships_feedback.levels.text')}
                        </option>
                        <option value="call">
                            {i18next.t('relationships_feedback.levels.call')}
                        </option>
                        <option value="meeting">
                            {i18next.t('relationships_feedback.levels.meeting')}
                        </option>
                        <option value="relationship">
                            {i18next.t('relationships_feedback.levels.relationship')}
                        </option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="relationship_fail_reasons_attributes">
                    <Form.Label>{i18next.t('relationships_feedback.fail_reason')} <RequiredQuestionMark/></Form.Label>
                    {fail_options.map((failOption) => (
                        <Form.Check className="bigCheckbox"
                                    type='checkbox'
                                    key={'relationship_fail_reasons_attributes.' + failOption}
                                    id={'relationship_fail_reasons_attributes.' + failOption}
                                    label={i18next.t(`relationships_feedback.${failOption}`)}
                                    onChange={props.onChange.bind(this)}
                                    checked={formData.relationship_fail_reasons_attributes &&
                                        formData.relationship_fail_reasons_attributes.some(e => (
                                            e.reason === failOption && !e.marked_as_deleted))}
                        />
                    ))}
                </Form.Group>
                <Form.Group className="mb-3" controlId="other_fail_reason">
                    <Form.Control type="text"
                                  placeholder={i18next.t('relationships_feedback.other')}
                                  value={formData.other_fail_reason}
                                  onChange={props.onChange.bind(this)}/>
                </Form.Group>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default UserDataSection;