import React from 'react'
import {Form, Accordion} from 'react-bootstrap';
import i18next from "i18next";
import FormCompletenessBadge from "./FormCompletenessBadge";

const OtherDataSection = (props) => {
    let formData = props.formData
    return (
        <Accordion.Item eventKey="4">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.other')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[formData.other_money, formData.other_smell,
                            formData.other_age, formData.other_distance]}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="other_money">
                    <Form.Label>
                        {i18next.t('relationships_feedback.other_money')}
                    </Form.Label>
                    <div key={'other_money'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="other_money"
                                type='radio'
                                readOnly
                                key={'other_money.' + i}
                                id={'other_money.' + i}
                                checked={i === parseInt(props.formData.other_money)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="other_smell">
                    <Form.Label>
                        {i18next.t('relationships_feedback.other_smell')}
                    </Form.Label>
                    <div key={'other_smell'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="other_smell"
                                type='radio'
                                readOnly
                                key={'other_smell.' + i}
                                id={'other_smell.' + i}
                                checked={i === parseInt(props.formData.other_smell)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="other_age">
                    <Form.Label>
                        {i18next.t('relationships_feedback.other_age')}
                    </Form.Label>
                    <div key={'other_age'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="other_age"
                                type='radio'
                                readOnly
                                key={'other_age.' + i}
                                id={'other_age.' + i}
                                checked={i === parseInt(props.formData.other_age)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="other_distance">
                    <Form.Label>
                        {i18next.t('relationships_feedback.other_distance')}
                    </Form.Label>
                    <div key={'other_distance'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="other_distance"
                                type='radio'
                                readOnly
                                key={'other_distance.' + i}
                                id={'other_distance.' + i}
                                checked={i === parseInt(props.formData.other_distance)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default OtherDataSection;
