import i18next from "i18next";
import {Link} from "react-router-dom";
import "./MainPage.css"
import {SettingsContext} from '../shared/SettingsContext';
import React, {useContext} from "react";

function MainPage() {
    const context = useContext(SettingsContext)
    return (
        <div id="main-page">
            <div id="main-page-card">
                <div>
                    {i18next.t('main_page.description')}
                </div>
                <ul>
                    <li>
                        <div>
                            <p>{i18next.t('main_page.feedback')}</p>
                            <p>
                                <a href={`https://dmni.tk/project-nietzsche-feedback`}>
                                    https://dmni.tk/project-nietzsche-feedback
                                </a>
                                {/*<Link to={`/feedback/`}>*/}
                                {/*    {`${window.location.origin}/feedback/`}*/}
                                {/*</Link>*/}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <p>{i18next.t('main_page.relationships_feedback')}</p>
                            <p>
                                <Link to={`/relationships-feedback/`}>
                                    {`${window.location.origin}/relationships-feedback/`}
                                </Link>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default MainPage