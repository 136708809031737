import React from 'react'
import "./RelationshipsFeedback.css"
import i18next from "i18next";

function RelationshipsFeedbackThanks() {
    return (
        <div>
            <div className={'hero-content'}>
                <h1>{i18next.t('relationships_feedback.thanks.thanks_message')}</h1>
            </div>
        </div>
    );
}

export default RelationshipsFeedbackThanks;
