import React from "react";

function Error404() {
    return (
        <div>
            <div className={'hero-content'}>
                <h1>ERROR 404</h1>
            </div>
        </div>
    );
}

export default Error404;