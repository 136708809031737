import React from 'react'
import logo from './logo.png';
import "./Header.css"
import {Navbar, Container, NavDropdown, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'
import i18next from "i18next";

function Header() {
    return (
        <header>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt="Project Nietzsche"
                            src={logo}
                            width="25%"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <NavDropdown title={<React.Fragment>
                            <FontAwesomeIcon icon={faGlobe}/>{' '}{i18next.t('header.language')}
                        </React.Fragment>} id="basic-nav-dropdown">
                            <NavDropdown.Item href="" onClick={() => {
                                i18next.changeLanguage('en');
                            }}>
                                {i18next.t('header.languages.english')}
                            </NavDropdown.Item>
                            <NavDropdown.Item href="" onClick={() => {
                                i18next.changeLanguage('ru');
                            }}>
                                {i18next.t('header.languages.russian')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;