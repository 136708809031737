import {useContext} from 'react'
import "./Footer.css"
import {SettingsContext} from './SettingsContext';
import i18next from "i18next";
import {Container, Nav, Navbar} from "react-bootstrap";

function Footer() {
    const context = useContext(SettingsContext)
    return (
        <footer>
            <Navbar bg="light" expand="lg" sticky="bottom">
                <Container>
                    <Nav className="me-auto">
                        <Navbar.Text>
                            &#169; {new Date().getFullYear()} Dimini Inc.
                        </Navbar.Text>
                        <Nav.Link href={`/about-site/`}>
                            {i18next.t('footer.about_site')}
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </footer>
    )
}

export default Footer;