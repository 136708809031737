import React, {Component, useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Navigate, Route, Routes, NavLink} from "react-router-dom"
import './App.css';
import MainPage from "./components/main/MainPage";
import axios from "axios";
import AboutSite from "./components/about_site/AboutSite";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Error404 from "./components/error/404";
import {SettingsContext} from "./components/shared/SettingsContext";
import RelationshipsFeedback from "./components/relationships_feedback/RelationshipsFeedback";
import RelationshipsFeedbackThanks from "./components/relationships_feedback/RelationshipsFeedbackThanks";
import './components/shared/Internationalization';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import {useTranslation} from "react-i18next";

function App() {
    // that hook will suspend render until translations would be initialized
    const {_} = useTranslation();

    return (
        <SettingsContext.Provider value={{
            theme: "light"
        }}>
            <Router>
                <Header/>
                <Routes>
                    <Route path='*' element={<Error404/>}/>
                    <Route path={`/`} element={<MainPage/>}/>
                    <Route path={`/relationships-feedback`} element={<RelationshipsFeedback/>}/>
                    <Route path={`/relationships-feedback/thanks`} element={<RelationshipsFeedbackThanks/>}/>
                    <Route path={`/relationships-feedback/:antispamParam`}
                           element={<RelationshipsFeedback/>}/>
                    <Route path={`/about-site`} element={<AboutSite/>}/>
                </Routes>
                <Footer/>
            </Router>
        </SettingsContext.Provider>
    );
}

export default App;
