import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
            debug: true,
            fallbackLng: 'en',
            backend: {
                loadPath: '/api/locales/{{lng}}/{{ns}}.json',
                customHeaders: {
                    Authorization: localStorage.token,
                }
            },
            react: {
                useSuspense: true
            },
        },
    )
    .then(() => {
        console.log('locales loaded');
    })
    .catch(err => console.err("Error: ", err));