import React from 'react'
import {Form, Accordion} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import FormCompletenessBadge from "./FormCompletenessBadge";

const PersonalityDataSection = (props) => {
    let formData = props.formData
    let checkedValues = [formData.personality_overall, formData.personality_character,
        formData.personality_adequacy, formData.personality_humor, formData.personality_intelligence,
        formData.personality_views, formData.personality_tastes]
    if (formData.max_level === "relationship") {
        checkedValues.push(formData.personality_sexual)
    }

    return (
        <Accordion.Item eventKey="2">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.personality')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[formData.personality_overall]}
                                               requiredQuestions={true}/>{' '}
                        <FormCompletenessBadge checkedValues={checkedValues}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="personality_overall">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_overall')} <RequiredQuestionMark/>
                    </Form.Label>
                    <div key={'personality_overall'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_overall"
                                type='radio'
                                readOnly
                                key={'personality_overall.' + i}
                                id={'personality_overall.' + i}
                                checked={i === parseInt(props.formData.personality_overall)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_character">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_character')}
                    </Form.Label>
                    <div key={'personality_character'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_character"
                                type='radio'
                                readOnly
                                key={'personality_character.' + i}
                                id={'personality_character.' + i}
                                checked={i === parseInt(props.formData.personality_character)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_adequacy">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_adequacy')}
                    </Form.Label>
                    <div key={'personality_adequacy'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_adequacy"
                                type='radio'
                                readOnly
                                key={'personality_adequacy.' + i}
                                id={'personality_adequacy.' + i}
                                checked={i === parseInt(props.formData.personality_adequacy)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_confidence">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_confidence')}
                    </Form.Label>
                    <div key={'personality_confidence'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_confidence"
                                type='radio'
                                readOnly
                                key={'personality_confidence.' + i}
                                id={'personality_confidence.' + i}
                                checked={i === parseInt(props.formData.personality_confidence)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_humor">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_humor')}
                    </Form.Label>
                    <div key={'personality_humor'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_humor"
                                type='radio'
                                readOnly
                                key={'personality_humor.' + i}
                                id={'personality_humor.' + i}
                                checked={i === parseInt(props.formData.personality_humor)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_intelligence">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_intelligence')}
                    </Form.Label>
                    <div key={'personality_intelligence'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_intelligence"
                                type='radio'
                                readOnly
                                key={'personality_intelligence.' + i}
                                id={'personality_intelligence.' + i}
                                checked={i === parseInt(props.formData.personality_intelligence)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_views">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_views')}
                    </Form.Label>
                    <div key={'personality_views'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_views"
                                type='radio'
                                readOnly
                                key={'personality_views.' + i}
                                id={'personality_views.' + i}
                                checked={i === parseInt(props.formData.personality_views)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="personality_tastes">
                    <Form.Label>
                        {i18next.t('relationships_feedback.personality_tastes')}
                    </Form.Label>
                    <div key={'personality_tastes'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="personality_tastes"
                                type='radio'
                                readOnly
                                key={'personality_tastes.' + i}
                                id={'personality_tastes.' + i}
                                checked={i === parseInt(props.formData.personality_tastes)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                {formData.max_level === "relationship" ?
                    <Form.Group className="mb-3" controlId="personality_sexual">
                        <Form.Label>
                            {i18next.t('relationships_feedback.personality_sexual')}
                        </Form.Label>
                        <div key={'personality_sexual'} className="mb-3">
                            {[1, 2, 3, 4, 5].map(i => (
                                <Form.Check
                                    inline
                                    label={i}
                                    name="personality_sexual"
                                    type='radio'
                                    readOnly
                                    key={'personality_sexual.' + i}
                                    id={'personality_sexual.' + i}
                                    checked={i === parseInt(props.formData.personality_sexual)}
                                    onClick={props.onChange.bind(this)}
                                />
                            ))}
                        </div>
                    </Form.Group> : null}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default PersonalityDataSection;
