import React from 'react'
import {Badge} from "react-bootstrap";

const FormCompletenessBadge = (props) => {
    let uncompletedColor = props.requiredQuestions ? "danger" : "secondary"
    return (
        <Badge bg={props.checkedValues.every(el => el) ? "success" : uncompletedColor}>
            {props.checkedValues.filter(el => el).length}/{props.checkedValues.length}
        </Badge>
    );
}

export default FormCompletenessBadge;