import React, {useState, useContext, useEffect} from 'react'
import "./RelationshipsFeedback.css"
import {Form, Button, Accordion, Alert} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import UserDataSection from "./UserDataSection";
import AppearanceDataSection from "./AppearanceDataSection";
import BehaviorDataSection from "./BehaviorDataSection";
import PersonalityDataSection from "./PersonalityDataSection";
import OtherDataSection from "./OtherDataSection";
import SummaryDataSection from "./SummaryDataSection";
import axios from "axios";
import {SettingsContext} from "../shared/SettingsContext";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../shared/Loading";

function RelationshipsFeedback() {
    const context = useContext(SettingsContext)
    let {antispamParam} = useParams();
    let history = useNavigate();
    const [timer, setTimer] = useState(null)
    const [formData, setFormData] = useState({antispam_id: null});
    const [incompleteForm, setincompleteForm] = useState(true);
    const [validLink, setvalidLink] = useState(null);
    const [antispamUsage, setantispamUsage] = useState(false);

    useEffect(() => {
        let antispam = antispamParam || ""
        formData["antispam_id"] = antispam
        setFormData(formData => ({...formData}))
        if (antispam) {
            checkAntispam(antispam)
        }
        if (antispamParam) {
            history(`/relationships-feedback/`, {replace: true});
        }
    }, []);

    function checkAntispam(antispam) {
        axios.get('/api/antispam/' + antispam)
            .then(response => {
                setvalidLink(response.data)
                if (response.data) {
                    axios.get('/api/antispam/' + antispam + '/set_opened')
                        .catch(error => console.log(error))
                }
            })
            .catch(error => console.log(error))
    }

    function submitForm(e) {
        e.preventDefault()
        axios.post('/api/relationship_feedback/submit', formData)
            .then(_ => {
                window.location = `/relationships-feedback/thanks`;
            })
            .catch(error => {
                setvalidLink(false)
                console.log(error)
            })
    }

    function onChange(e) {
        clearTimeout(timer);
        let field_name = e.target.id;
        let current_element = e.target;
        if (current_element.type === 'checkbox') {
            let sub_name;
            [field_name, sub_name] = field_name.split('.')
            if (!formData[field_name]) {
                formData[field_name] = []
            }
            let element = formData[field_name].find(el => el.reason === sub_name)
            if (element) {
                formData[field_name].forEach(el => {
                    if (el.reason === sub_name) el.marked_as_deleted = !el.marked_as_deleted;
                })
            } else {
                formData[field_name].push({reason: sub_name, marked_as_deleted: false})
            }
            setFormData({...formData})
        } else if (current_element.type === 'radio') {
            let index;
            [field_name, index] = field_name.split('.')
            if (formData[field_name] == null || formData[field_name].toString() !== index) {
                formData[field_name] = index;
            } else {
                formData[field_name] = null
            }
            setFormData({...formData});
        } else {
            if (e.target.value !== e.target.ariaLabel) {
                formData[field_name] = e.target.value;
            } else {
                formData[field_name] = null;
            }
            if (field_name === 'max_level' && e.target.value !== 'relationship') {
                formData['appearance_dick'] = null
                formData['personality_sexual'] = null
            }
            setFormData({...formData});
        }
        if (formData['relationship_fail_reasons_attributes'] &&
            formData['relationship_fail_reasons_attributes'].filter(el => !el.marked_as_deleted).length > 0 &&
            formData['appearance_overall'] && formData['personality_overall'] && formData['behavior_overall']
            && formData['overall']) {
            setincompleteForm(false)
        } else {
            setincompleteForm(true)
        }
        if (!antispamUsage) {
            axios.get('/api/antispam/' + formData.antispam_id + '/set_used')
                .then(_ => {
                    setantispamUsage(true)
                })
                .catch(error => console.log(error))
        }
        setTimer(setTimeout(function () {
            axios.post('/api/relationship_feedback/handle_edit', formData)
        }, 3000));
    }

    return (
        <div id={"relationships-feedback"}>
            {formData.antispam_id && validLink ?
                <div id="relationships-feedback-card">
                    {/*<Form action="/api/relationships_feedback/submit" method="post">*/}

                    <Form onSubmit={submitForm}>
                        <h1>{i18next.t('relationships_feedback.title')}</h1>
                        <p>{i18next.t('relationships_feedback.required_questions')}
                            <RequiredQuestionMark/>
                        </p>
                        <p>{i18next.t('relationships_feedback.other_options')}
                            <br/>
                            <a href={`https://dmni.tk/project-nietzsche-feedback`}>
                                https://dmni.tk/project-nietzsche-feedback
                            </a>
                        </p>
                        <Accordion defaultActiveKey="0">
                            <UserDataSection onChange={onChange}
                                             formData={formData}/>
                            <AppearanceDataSection onChange={onChange}
                                                   formData={formData}/>
                            <PersonalityDataSection onChange={onChange}
                                                    formData={formData}/>
                            <BehaviorDataSection onChange={onChange}
                                                 formData={formData}/>
                            <OtherDataSection onChange={onChange}
                                              formData={formData}/>
                            <SummaryDataSection onChange={onChange}
                                                formData={formData}/>
                        </Accordion>
                        <br/>
                        {incompleteForm ?
                            <Alert variant="danger">
                                {i18next.t('relationships_feedback.incomplete')}
                            </Alert> : null}
                        <div className="d-grid gap-2">
                            <Button variant="primary" size="lg" type="submit"
                                    disabled={incompleteForm}>
                                {i18next.t('relationships_feedback.submit')}
                            </Button>
                        </div>
                        <br/>
                        <br/>
                    </Form>
                </div> : null}
            {validLink === null && formData.antispam_id !== '' ?
                <Loading/> : null}
            {validLink === false || formData.antispam_id === '' ?
                <div id="relationships-feedback-card" className={'hero-content'}>
                    <h1>{i18next.t('relationships_feedback.no_permission')}</h1>
                </div> : null}
        </div>
    );
}

export default RelationshipsFeedback;
