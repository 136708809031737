import React from 'react'
import {Form, Accordion} from 'react-bootstrap';
import i18next from "i18next";
import RequiredQuestionMark from "./RequiredQuestionMark";
import FormCompletenessBadge from "./FormCompletenessBadge";

const BehaviorDataSection = (props) => {
    let formData = props.formData
    return (
        <Accordion.Item eventKey="3">
            <Accordion.Header>
                <div className={"row w-100"}>
                    <div className={"col"}>{i18next.t('relationships_feedback.behavior')}</div>
                    <div className={"col right-aligned"}>
                        <FormCompletenessBadge checkedValues={[formData.behavior_overall]}
                                               requiredQuestions={true}/>{' '}
                        <FormCompletenessBadge checkedValues={[formData.behavior_overall, formData.behavior_general,
                            formData.behavior_communication, formData.behavior_actions, formData.behavior_speech,
                            formData.behavior_movement, formData.behavior_attitude]}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="behavior_overall">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_overall')} <RequiredQuestionMark/>
                    </Form.Label>
                    <div key={'behavior_overall'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_overall"
                                type='radio'
                                readOnly
                                key={'behavior_overall.' + i}
                                id={'behavior_overall.' + i}
                                checked={i === parseInt(props.formData.behavior_overall)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_general">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_general')}
                    </Form.Label>
                    <div key={'behavior_general'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_general"
                                type='radio'
                                readOnly
                                key={'behavior_general.' + i}
                                id={'behavior_general.' + i}
                                checked={i === parseInt(props.formData.behavior_general)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_communication">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_communication')}
                    </Form.Label>
                    <div key={'behavior_communication'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_communication"
                                type='radio'
                                readOnly
                                key={'behavior_communication.' + i}
                                id={'behavior_communication.' + i}
                                checked={i === parseInt(props.formData.behavior_communication)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_actions">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_actions')}
                    </Form.Label>
                    <div key={'behavior_actions'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_actions"
                                type='radio'
                                readOnly
                                key={'behavior_actions.' + i}
                                id={'behavior_actions.' + i}
                                checked={i === parseInt(props.formData.behavior_actions)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_speech">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_speech')}
                    </Form.Label>
                    <div key={'behavior_speech'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_speech"
                                type='radio'
                                readOnly
                                key={'behavior_speech.' + i}
                                id={'behavior_speech.' + i}
                                checked={i === parseInt(props.formData.behavior_speech)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_movement">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_movement')}
                    </Form.Label>
                    <div key={'behavior_movement'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_movement"
                                type='radio'
                                readOnly
                                key={'behavior_movement.' + i}
                                id={'behavior_movement.' + i}
                                checked={i === parseInt(props.formData.behavior_movement)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="behavior_attitude">
                    <Form.Label>
                        {i18next.t('relationships_feedback.behavior_attitude')}
                    </Form.Label>
                    <div key={'behavior_attitude'} className="mb-3">
                        {[1, 2, 3, 4, 5].map(i => (
                            <Form.Check
                                inline
                                label={i}
                                name="behavior_attitude"
                                type='radio'
                                readOnly
                                key={'behavior_attitude.' + i}
                                id={'behavior_attitude.' + i}
                                checked={i === parseInt(props.formData.behavior_attitude)}
                                onClick={props.onChange.bind(this)}
                            />
                        ))}
                    </div>
                </Form.Group>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default BehaviorDataSection;
