import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAsterisk} from '@fortawesome/free-solid-svg-icons'

function RequiredQuestionMark() {
    return (
        <span style={{color: "red"}}>
            <FontAwesomeIcon icon={faAsterisk}/>
        </span>
    );
}

export default RequiredQuestionMark;
